import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import APILink from "../service/APILink";
import { setIsLogin } from '../redux/accction/acction';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
function Login() {
  const { Formik } = formik;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(false);
  const schema = yup.object().shape({
    name: yup.string().required(),
    password: yup.string().required(),
  });
  const LoginFunn = async (e, values, resetForm) => {
    e.preventDefault();
    setLoad(true);
    const APIRouter = status === false ? "login" : "register";
    try {
      const response = await APILink.post(APIRouter, values);
      if (response.data.status === 'success') {
        if (status === false) {
          Cookies.set('token', response.data.token, { expires: 30, secure: true, sameSite: 'Strict' });
          setTimeout(() => {
            navigate('/');
          }, 800);
          dispatch(setIsLogin(true));
        } else {
          setStatus(false)
        }
        toast.success(`${status === false ? "Đăng nhập" : "Đăng ký"}  thành công`);
        resetForm();

      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <div className='w-100 flex-class justify-content-center position-relative login-page'>
      <div className='overlay w-100 h-100'> </div>
      <Formik
        validationSchema={schema}
        initialValues={{
          name: '',
          password: '',
          code: ""
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors, resetForm }) => (
          <Form className='login w-95 p-4'>
            <div className='login-title flex-class justify-content-center mt-3 mb-3'>
              <h3 className={`titel-span-text color-white fs-5 ${status === false && "active"}`} onClick={() => setStatus(false)}>Đăng nhập</h3>
              <h3 className={`titel-span-text color-white ms-3 fs-5 ${status === true && "active"}`} onClick={() => setStatus(true)}>Đăng ký</h3>
            </div>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="12"
                className='mt-3 mb-3'

              >
                <Form.Label className='color-white'>Tên đăng nhập (Số điện thoại hoặc Gmail)</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  className='form-input pt-2 pb-2 bg-transparent color-white '
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                />
                <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                className='mt-3 mb-3'
              >
                <Form.Label className='color-white'>Mật khẩu</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  className='form-input pt-2 pb-2 bg-transparent color-white '
                  value={values.password}
                  onChange={handleChange}
                  isValid={touched.password && !errors.password}
                />

                <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
              </Form.Group>
              {status === true &&
                (
                  <Form.Group
                    as={Col}
                    md="12"
                    className='mt-3 mb-3'

                  >
                    <Form.Label className='color-white'>Mã giới thiệu</Form.Label>
                    <Form.Control
                      type="text"
                      name="code"
                      className='form-input pt-2 pb-2 bg-transparent color-white '
                      value={values.code}
                      onChange={handleChange}
                      isValid={touched.code && !errors.code}
                    />
                    <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                )
              }
             {status === false && (
                <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                  <Link className='float-end color-white' to="/forgot-password">Quên mật khẩu ?</Link>
                </Form.Group>
              )}

            </Row>

            <Button
              type="submit"
              disabled={load}
              className='btn-login bg-transparent w-100 pt-3 pb-3'
              onClick={(e) => LoginFunn(e, values, resetForm)} >
              {status === true ? "Đăng ký" : "Đăng nhập"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Login;