import React, { useState } from 'react';
import Image1 from "../../asset/img/imgTable1.png"
import Image2 from "../../asset/img/icon10.png";
import Image3 from "../../asset/img/icon9.png";
import Image4 from "../../asset/img/icon3.png";
import { useNavigate } from 'react-router-dom';

function TabListLayer() {
    const [value, setValue] = useState('review');
    const navigate = useNavigate();
    const handleChange = (newValue) => {
        setValue(newValue);
            switch (newValue) {
                case 'review':
                    navigate('/review');
                    break;
                case 'deposit':
                    navigate('/deposit-withdraw');
                    break;
                case 'withdraw':
                    navigate('/deposit-withdraw');
                    break;
                default:
                    break;
            }
    };
    return (
        <div className="tabListLayer d-flex flex-row gap-2 w-100 p-4">
            <div className="home-card">
                <div className="image-tableLayer flex-class justify-content-center" onClick={()=>handleChange('review')}>
                    <img className="w-80 responsive-image" src={Image1}/>
                </div>
                <p className="fs-6 mt-3">
                    Hồ sơ công ty
                </p>
            </div>
            <div className="home-card">
                <div className="image-tableLayer flex-class justify-content-center" onClick={()=>handleChange('deposit')}>
                    <img className="w-80 responsive-image" src={Image2}/>
                </div>
                <p className="fs-6 mt-3">
                    Tối muốn gửi tiền
                </p>
            </div>
            <div className="home-card">
                <div className="image-tableLayer flex-class justify-content-center" onClick={()=>handleChange('withdraw')}>
                    <img className="w-80 responsive-image" src={Image3}/>
                </div>
                <p className="fs-6 mt-3">
                    Tôi muốn rút tiền
                </p>
            </div>
            <div className="home-card d-none">
                <div className="image-tableLayer flex-class justify-content-center">
                    <img className="w-80 responsive-image" src={Image4}/>
                </div>
                <p className="fs-6 mt-3">
                    Tin tức mới nhất
                </p>
            </div>
        </div>
    );
}

export default TabListLayer;