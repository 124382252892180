import '../asset/css/chat.css';
import {Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import React, {useState, useEffect, useRef} from 'react';
import APILink from '../service/APILink';
import {toast} from 'react-toastify';
import {formatDate} from "../service/funWeb";
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import Echo from 'laravel-echo';
import {useSelector, useDispatch} from 'react-redux';
import {formatDate1} from "../service/funWeb"
import InfiniteScroll from 'react-infinite-scroll-component';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {setUser} from '../redux/accction/acction';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {Pagination} from 'swiper/modules';
import notificationSound  from '../asset/audio/simple-notification.mp3'
window.io = io;
window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: process.env.REACT_APP_REALTIME,
    transports: ['websocket']
});
window.Echo.connector.socket.on('connect', () => {
    console.log('Kết nối được đến server');
});
window.Echo.connector.socket.on('error', function (error) {
    console.error("Error connecting to Laravel Echo Server:", error);
});

function ChatRealTime() {
    const audio = new Audio(notificationSound);
    const [id, setId] = useState(0);
    const [user_id, setuserId] = useState(0);
    const [data, setData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [listmessageChat, setListmessageChat] = useState([]);
    const [message, setMessage] = useState('');
    const [activeUserId, setActiveUserId] = useState(0);
    const [image, setImage] = useState(null);
    const [idChat, setIdChat] = useState(0);
    const [page, setPage] = useState(1);
    const [pageUser, setPageUser] = useState(1);
    const [limit, setLimit] = useState(10);
    const [isTokenSet, setIsTokenSet] = useState(false);
    const user = useSelector((state) => state.reducers.user);
    const [isloadMore, setIsLoadmore] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [isloadMoreList, setIsLoadmoreList] = useState(true);
    const [isLoadingList, setLoadingList] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [idUpdate, setIdUpdate] = useState(null)
    const messageContainerRef = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [lastMessageCreat_at , setLastMessageCreat_at] = useState(null)
    const fileInputRef = useRef(null);
    const inputRef = useRef(null);
    const [isFull, setIsFull] = useState(false);

    useEffect(() => {
        if (inputRef.current) {
            const inputElement = inputRef.current;
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = window.getComputedStyle(inputElement).font;

            const textWidth = context.measureText(message).width;
            const paddingLeft = parseFloat(window.getComputedStyle(inputElement).paddingLeft);
            const paddingRight = parseFloat(window.getComputedStyle(inputElement).paddingRight);

            const inputWidth = inputElement.clientWidth - paddingLeft - paddingRight;
            if (textWidth >= inputWidth) {
                setIsFull(true);
            } 
        }
    }, [message]);
    useEffect(() => {
        if (isFull && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isFull]);
    useEffect(() => {
        if (user !== null) {
            if(lastMessageCreat_at === null){
                callAPiFun(false);

            }
            if (user.role_id === 1) {
                if(lastMessageCreat_at !== null){
                    const intervalId = setInterval(() => {
                        callAPiFun(true);
                    }, 30000);
                    return () => clearInterval(intervalId);
                }
              
            }
        }
    }, [user, pageUser,lastMessageCreat_at]);
    useEffect(() => {
        if (data.length > 0) {
            const var_id = user !== null && user.role_id === 1 ? user_id : 1;
            user_id > 0 && callGetListChat(user_id);
        }

    }, [user_id, page, limit, data]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token && !Cookies.get('token')) {
            Cookies.set('token', token, {expires: 30});
            setIsTokenSet(true);
        } else if (Cookies.get('token')) {
            setIsTokenSet(false);
        }
    }, []);
    const id_chat = user_id;
    let chatChannel = null;
    let id_receiver = null;
    useEffect(() => {
        if (user && user.role_id !== 1) {
            chatChannel = user_id;
            id_receiver = user ? user.id : 1;
        } else {
            chatChannel = user ? user.id : 1
            id_receiver = user_id;
        }

        const channelName = `laravel_database_chatrealtimeadmin${chatChannel}-user${id_receiver}`;
        const channel = window.Echo.channel(channelName);

       
        const messageListener = (e) => {
            //  const audio = new Audio(notificationSound);
            //         audio.play().catch(error => {
            //             console.error('Error playing sound:', error);
            //         });
            if (e.type === "get") {
                if (user !== null && e.message.user_id === id_chat) {
                    // const audio = new Audio('https://cdn.freesound.org/previews/743/743323_16127874-lq.mp3');
                    const audio = new Audio(notificationSound);
                    audio.play().catch(error => {
                        console.error('Error playing sound:', error);
                    });
                } else {
                    // const audio = new Audio('https://cdn.freesound.org/previews/743/743323_16127874-lq.mp3');
                    const audio = new Audio(notificationSound);
                    audio.play().catch(error => {
                        console.error('Error playing sound:', error);
                    });
                }
                    console.log(id_chat)
                setListmessageChat(prevMessages => [e.message, ...prevMessages]);
            } else if (e.type === "delete") {
                if (user && user_id.role_id !== 1 && user_id.id !== 1) {
                    callGetListChat(id_chat);
                } else {
                    setListmessageChat(prevMessages =>
                        prevMessages.filter(message => message.id !== e.id)
                    );
                }
            } else if (e.type === "update") {
                if (user && user_id.role_id !== 1 && user_id.id !== 1) {
                    callGetListChat(id_chat);
                } else {
                    setListmessageChat(prevMessages =>
                        prevMessages.map(message =>
                            message.id === e.id ? {...message, ...e.message} : message
                        )
                    );
                }
            }
        };

        channel.listen('MessageSent', messageListener);

        return () => {
            channel.stopListening('MessageSent', messageListener);
            window.Echo.leave(channelName);
        };
    }, [user, user_id, id_chat]);

    // useEffect(() => {
    //     if (user !== null && user.role_id !== 1) {
    //         setMessage('Tôi cần sự giúp đỡ');
    //         setuserId(1)
    //     }
    // }, [user])
    const callAPiFun = (type = true) => {
        setLoadingList(true);
        const config = isTokenSet ? {
            headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        } : {};
        APILink.get(`list-user-chat?page=${pageUser}&limit=8`, config)
            .then((response) => {
                if (response.data.status === 'success') {
                    const users = response.data.result;
                    setData(prevMessages => pageUser === 1
                        ? response.data.result
                        : [...prevMessages, ...response.data.result]
                    );
                    setIsAdmin(response.data.isAdmin);
                    const firstUser = users[0];
                   
                    if (type === false) {
                        setActiveUserId(firstUser.id);
                        setId(response.data.user_id);
                        setuserId(firstUser.id)
                        setIdChat(response.data.user_id);
                        users.length > 0 &&    setLastMessageCreat_at(users[0].latest_message.created_at);
                    }
                    else{
                        console.log(users[0].latest_message.created_at);
                        console.log(lastMessageCreat_at)
                       
                                            if (lastMessageCreat_at !== users[0].latest_message.created_at) {
                                                setLastMessageCreat_at(users[0].latest_message.created_at);
                                                const audio = new Audio(notificationSound);
                                                audio.play().catch(error => {
                                                    console.error('Error playing sound:', error);
                                                });
                                            }
                                                                }
                   
                    if (response.data.count < limit) {
                        setIsLoadmoreList(false);
                    } else {
                        setIsLoadmoreList(true);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
            setLoadingList(false);
        });
    };


    const callGetListChat = (user_id) => {
        const config = isTokenSet ? {
            headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        } : {};
        setLoading(true); // Bắt đầu trạng thái tải dữ liệu'
        APILink.get(`get-content-chat?id=${user_id}&page=${page}&limit=${limit}`, config)
            .then((response) => {
                if (response.data.status === 'success') {
                    setListmessageChat(prevMessages => page === 1
                        ? response.data.result
                        : [...prevMessages, ...response.data.result]
                    );

                    if (response.data.result.length < limit) {
                        setIsLoadmore(false);

                    } else {
                        setIsLoadmore(true); // Còn dữ liệu để tải thêm
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false); // Đặt trạng thái tải xong
            });
    };

    const deleteMessageId = (item) => {
        APILink.delete(`delete-message/${item.id}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success("Xóa tin nhắn thành công");

                } else {
                    toast.error(response.data.message || "Xóa tin nhắn thất bại");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("An error occurred. Please try again.");
            });
    };
    const handleInputChange = (event) => {
        const value = event.target.value;
        setMessage(value);
        setCursorPosition(event.target.selectionStart);
    };


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
    }, [isFull, cursorPosition]);
    const postMessage = () => {
        //  handleInputChange();
        if (!message.trim() && !image) {
            toast.error("Vui lòng gửi tin nhắn hoặc ảnh");
            return;
        }

        const formData = new FormData();
        formData.append('receiver_id', user_id);

        if (message.trim()) {
            formData.append('message', message);
        }

        if (image) {
            formData.append('file', image);
        }

        const url = image !== null ? "send-Image" : "send-message";
        APILink.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    setMessage('');
                    setImage(null);
                    setPreviewImage(null);
                    setIsFull(false)
                } else {
                    toast.error(response.data.message || "Gửi tin nhắn thất bại");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("An error occurred. Please try again.");
            });
    };


    const updateMessage = () => {
        /// handleInputChange();
        if (!message.trim() && !image) {
            toast.error("Vui lòng gửi tin nhắn hoặc ảnh");
            return;
        }

        const formData = new FormData();
        formData.append('receiver_id', user_id);

        if (message.trim()) {
            formData.append('message', message);
        }

        if (image) {
            formData.append('file', image);
        }

        const url = image !== null ? "update-Image" : "update-message";
        APILink.post(`${url}/${idUpdate}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    setMessage('');
                    setImage(null);
                    setIsUpdate(false);
                    setPreviewImage(null);
                    setIsFull(false)
                } else {
                    toast.error(response.data.message || "Gửi tin nhắn thất bại");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("An error occurred. Please try again.");
            });
    };

    const deleteMessage = (item) => {

        confirmAlert({
            title: 'Xóa tin nhắn',
            message: 'Bạn có muốn xóa tin nhắn không ?',
            buttons: [
                {
                    label: 'Có',
                    onClick: () => deleteMessageId(item)
                },
                {
                    label: 'Không',
                }
            ]
        });
    }
    const showUpdate = (item) => {

        if (item.type !== "image") {
            setMessage(item.content);
        } else {
            setMessage("");
            const imageURL = process.env.REACT_APP_SERVER_URL + item.content;
            console.log(imageURL)
            if (imageURL) {
                setPreviewImage(imageURL); // Sử dụng URL trực tiếp
            }
        }
        setIdUpdate(item.id)
        setIsUpdate(true)
    }

    const handleUserClick = (userId) => {
        setPage( 1);
       if(activeUserId === userId) return;
       setuserId(userId);
       const selectedUser = data.find(user => user.id === userId);
       if (selectedUser) {
           setListmessageChat(selectedUser.messages && selectedUser.messages.length > 0 ? selectedUser.messages : []);
       }
       setActiveUserId(userId);
       setIdChat(userId)
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);

            const previewURL = URL.createObjectURL(file);
            setPreviewImage(previewURL);
            toast.success('Chọn ảnh thành công')
        }
    };


    const handleButtonClick = (e) => {
        e.preventDefault()
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleScroll = () => {
        const container = messageContainerRef.current;
        const scrollTop = container.scrollTop;
        const clientHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;
        if (scrollTop <= 0 && isloadMore === true) {
            console.log('Đã cuộn lên gần đầu');
            loadMoreMessages();
        }
    };

    const loadMoreMessages = () => {
        if (!isLoading && isloadMore) {
            setLoading(true); // Đặt trạng thái đang tải
            setPage(prevPage => prevPage + 1); // Tăng giá trị page
        }
    };


    const loadMoreUser = () => {
        if (!isLoadingList && isloadMoreList) {
            setLoadingList(true)
            setPageUser(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.scrollLeft = inputRef.current.scrollWidth;
        }
    }, [message]);
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (e.shiftKey) {
            if (!isFull) {
              setIsFull(true);
              setTimeout(() => {
                const newValue = message + '\n';
                setMessage(newValue);
                inputRef.current.setSelectionRange(message.length, message.length);
              }, 0);
            } else {
              const { selectionStart, selectionEnd } = inputRef.current;
              setMessage(
                message.slice(0, selectionStart) + '\n' + message.slice(selectionEnd)
              );
              setTimeout(() => {
                inputRef.current.setSelectionRange(selectionStart + 1, selectionStart + 1);
              }, 0);
            }
          } else {
            isUpdate ? updateMessage() : postMessage();
          }
        }
      };
    return (
        <div className='w-100 d-flex justify-content-center' style={{height: window.innerWidth <= 768 ? '70%' : '100%'}}>
            <div className='row h-100 g-0' style={{width: "80%"}}>
                <h1 className='text-center pt-4 pb-4'>Chăm sóc khách hàng</h1>
                <div className='list-user-chat col-12 h-100 col-lg-4 p-4 d-none d-lg-block'>
                    <Sidebar className='ps-sidebar-custorm w-100 h-100'>
                        <Menu>
                            {
                                isAdmin &&
                                <div className='d-flex justify-content-center mt-2 mb-2'>
                                    <input type="text" id="search-user" className='form-control w-50' placeholder='Tìm kiếm người dùng'/>
                                    <button className='btn btn-primary ms-2' onClick={(e) =>{
                                                const value = document.getElementById('search-user').value;
                                                if(value.length > 0){
                                                APILink.get(`search-user-chat?name=${value}`)
                                                .then((response) => {
                                                if (response.data.status === 'success') {
                                                setData(response.data.result);
                                                }
                                            })
                                                .catch((error) => {
                                                console.error(error);
                                            });
                                            } else {
                                                callAPiFun(false);
                                            }
                                        }}
                                    >Tìm kiếm</button>
                                </div>
                            }
                            {data.length > 0 && data.map((item, index) => {
                                return (
                                    <MenuItem onClick={() => handleUserClick(item.id)} key={item.id}
                                              className={`pt-3 pb-3 ps-2 pe-2 link-user ${activeUserId === item.id ? "active" : ""}`}>
                                        <div className='d-flex'>
                                            <img className='img-user'
                                                 src={process.env.REACT_APP_SERVER_URL + item.avatar}/>
                                            <div>
                                                <b className='ms-3 fs-6'> {item.full_name || item.name} </b>

                                                <p className='ms-3 fs-7'>
                                                    {item.latest_message !== null
                                                        ? (item.latest_message.type === "image"
                                                                ? "ảnh"
                                                                : item.latest_message.content
                                                        )
                                                        : "Không có tin nhắn mới"}
                                                </p>
                                            </div>
                                        </div>
                                    </MenuItem>
                                )
                            })}

                        </Menu>

                    </Sidebar>;
                    {user !== null && (user.id === 1 || user.role_id === 1) && isloadMoreList === true ? (
                        <div className='add-btn d-flex justify-content-center align-items-center'
                             onClick={loadMoreUser}>
                            <i class="fa-solid fa-plus"></i>
                        </div>) : null}

                </div>
                <div className='list-user-chat col-12  col-lg-4 p-4 d-block d-lg-none'>
                    <Swiper
                        slidesPerView={3}
                        slidesPerGroup={3}  // Add this property to move 4 slides at a time
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {data.length > 0 && data.map((item) => {
                            return (
                                <SwiperSlide
                                    onClick={() => handleUserClick(item.id)}
                                    key={item.id}
                                    className={`pt-3 text-center pb-3 ps-2 pe-2 link-user ${activeUserId === item.id ? "active" : ""}`}
                                >
                                    <div className='d-block'>
                                        <img
                                            className='img-user'
                                            src={process.env.REACT_APP_SERVER_URL + item.avatar}
                                            alt={item.full_name || item.name}
                                        />

                                        <div className='w-100 text-center mt-3'>
                                            <b>{item.full_name || item.name}</b>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    {user !== null && (user.id === 1 || user.role_id === 1) && isloadMoreList === true ? (
                        <div className='add-btn d-flex justify-content-center align-items-center'
                             onClick={loadMoreUser}>
                            <i class="fa-solid fa-plus"></i>
                        </div>) : null}

                </div>
                <div className='col-12 col-lg-8 box-chat h-100 d-flex justify-content-center'>
                    <div className='w-100 h-100'>
                        <div id="messageList" className='box-main h-100 d-flex w-100 box-message '
                             onScroll={handleScroll} ref={messageContainerRef}>
                            {listmessageChat.length > 0 && listmessageChat.map((item, index) => {
                                return (
                                    <div
                                        className={`box-message-item mt-3 mb-3 ${user !== null && item.user_id === user.id ? "ms-auto align-self-end" : "me-auto align-self-start"}`}
                                        key={index}
                                    >
                                        <small className='timespan'>{formatDate1(item.created_at)}</small>
                                        {item.type === "image" ? (<img className='img-content'
                                                                       src={process.env.REACT_APP_SERVER_URL + item.content}/>) : (
                                            <p>{item.content} </p>)}
                                        {user !== null && (user.id === 1 || user.role_id === 1) && isloadMoreList === true ? (
                                            <div
                                                className='btn-acction d-flex flex-column justify-content-between h-100'>
                                                <div
                                                    className='d-flex justify-content-center align-items-center btn-acction-item'
                                                    onClick={() => showUpdate(item)}>
                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                </div>
                                                <div
                                                    className='d-flex justify-content-center align-items-center btn-acction-item'
                                                    onClick={() => deleteMessage(item)}>
                                                    <i class="fa-solid fa-trash"></i>
                                                </div>
                                            </div>) : null}

                                    </div>
                                )
                            })}

                        </div>
                        <div className='box-footer w-100 d-flex justify-content-center align-items-center'>
                            <input
                                className='d-none'
                                ref={fileInputRef}
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                onChange={handleFileChange}
                            />
                            <div className='form-post'>
                                {isFull === false ?


                                    (<input
                                        className='w-100 h-100 text-input'
                                        value={message}
                                        ref={inputRef}
                                        onChange={(e) => handleInputChange(e)}
                                        onKeyDown={handleKeyDown}
                                    />) : (<textarea
                                        className='w-100 h-100 text-input'
                                        value={message}
                                        ref={inputRef}
                                       rows="4"
                                        onChange={(e) => handleInputChange(e)}
                                        onKeyDown={handleKeyDown}
                                    />)}


                                {previewImage !== null || image !== null ? (
                                    <img src={previewImage} alt="Preview" className='img-preview'
                                         onClick={handleButtonClick}/>
                                ) : (<button className='d-flex justify-content-center align-items-center upload-file'
                                             onClick={handleButtonClick}>
                                    <i className="fa-solid fa-plus"></i>
                                </button>)}
                                <button className='d-flex justify-content-center align-items-center btn-send'
                                        onClick={isUpdate === false ? postMessage : updateMessage}>
                                    <i class="fa-solid fa-paper-plane"></i>
                                </button>

                                {/* <input className='btn-send upload-file' type='file' /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatRealTime;

